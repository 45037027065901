import ApplicationController from './application_controller';
import { stringify } from 'querystring';
import { leave } from 'el-transition';

export default class extends ApplicationController {
    static targets = ['delete'];

    connect() {}

    delete(e) {
        e && e.preventDefault();
        var context = this;
        var r = confirm('Are you sure you want to delete this subcategory?');
        var label = this.deleteTarget.getAttribute('data-label');
        var jem = this.deleteTarget.getAttribute('data-jemid');
        var jetiname = this.deleteTarget.getAttribute('data-jetiname');

        if (r == true) {
            this.ajax({
                url: e.currentTarget.href,
                type: 'POST',
                dataType: 'json',
                data: stringify({
                    label: label,
                    jem_id: jem,
                    jeti_name: jetiname,
                }),
                complete: function (data) {
                    let res = JSON.parse(data.response);
                    if (res.error) {
                        context.showAlert('Error: ' + res.error['Fault']['Error'][0]['Message'], {
                            type: 'error',
                            time: 6000,
                        });
                    } else {
                        leave(context.element);
                        context.showAlert('Subcategory ' + res.label + ' deleted.', {
                            type: 'success',
                            time: 3000,
                        });
                    }
                },
            });
        }
    }
}
