import ApplicationController from './application_controller';

export default class extends ApplicationController {
    static targets = ['defaultCost', 'variantCost', 'defaultCostButton'];

    connect() { }

    showMessage() {
        var message = 'Performing action';
        this.showAlert(`${message}`, { type: 'success' });
    }

    disableApplyAllButton() {
        if(this.defaultCostTarget.value == '') {
            this.defaultCostButtonTarget.classList.add('form-disabled');
            this.defaultCostButtonTarget.setAttribute("disabled", "true");
        } else {
            this.defaultCostButtonTarget.classList.remove('form-disabled');
            this.defaultCostButtonTarget.removeAttribute("disabled");
        }
    }
    applyDefaultCostToVariants(e) {
        e.preventDefault();
        if(this.defaultCostTarget.value < 0) {
            this.showAlert('Cost cannot be negative', { type: 'error' });
            return;
        }
        this.variantCostTargets.forEach((element) => {          
            element.value = this.defaultCostTarget.value;
        });
    }
}